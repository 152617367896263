.react-slideshow-zoom-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-zoom-wrapper .zoom-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.react-slideshow-zoom-wrapper .zoom-wrapper > div {
  position: relative;
  display: flex;
}
