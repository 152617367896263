.react-slideshow-fade-wrapper {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
  display: flex;
  flex-wrap: wrap;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap > div {
  position: relative;
  opacity: 0;
}
